.intro {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.intro img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}