header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
}
header img {
  margin-top: 30px;
  width: 100%;
  height: calc(100% - 30px);
  max-width: 300px;
  object-fit: contain;
}