.join {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.join a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 99vh;
}
.join img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}