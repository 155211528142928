main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.tinderCards {
  position: relative;
  width: 100%;
  height: 50vh;
  z-index: 999;
}
.tinderCards__cardContainer {
  display: flex;
  justify-content: center;
  padding-top:10px;
}
.swipe {
  position: absolute;
  height: auto;
}
.card {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  padding-top: 96%;
  width: 400px;
  max-width: 85vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 18px 33px 0px rgba(0,0,0,0);
  transition: box-shadow 100ms ease-out;
}
.card.top {
  box-shadow: 0px 18px 33px 0px rgba(0,0,0,0.3);
}
.card > h1 {
  text-align: center;
  display: flex;
  align-self: center;
}
.card > h3 {
  color: white;
  font-size: 2rem;
  margin-bottom: 0;
}
.message {
  display: flex;
  justify-content: center;
  padding: 5px;
  font-size: small;
}

.swipe-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2vh;
  height: calc(20vh - 2vh);
}
.swipe-buttons img.swipe-title {
  width: 100%;
  height: auto;
  max-width: 400px;
  object-fit: contain;
}
.swipe-buttons .swipe-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1auto;
  max-width: 400px;
}

.swipe-buttons .swipe-arrows button {
  display: flex;
  width: 50%;
  height: auto;
  object-fit: contain;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;

}

.swipe-buttons .swipe-left-arrow {
  width: 100%;
  height: auto;
  border-right: 4px solid transparent;
}
.swipe-buttons .swipe-right-arrow {
  width: 100%;
  height: auto;
  border-left: 4px solid transparent;
}