.hideEnd {
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
}
.showEnd {
  z-index:99999;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  animation: showEnd 0.3s ease-in-out forwards;
  transform: translate(0, 40px);
}
@keyframes showEnd {
  from {
    opacity: 0;
  transform: translate(0, 40px);
  }
  to {
    opacity: 1;
  transform: translate(0, 0);
  }
}
.showEnd img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}