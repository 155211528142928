footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0;
  height: 13vh;
}
footer img.footer-logo {
  width: 100%;
  height: auto;
  max-width: 180px;
  object-fit: contain;
  margin: 4vh auto;
}